* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: 'Poppins', sans-serif;
}
body {
  background: url("/public/images/bg.jpg") center center/cover no-repeat;
}
.container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}
.promo {
  padding: 64px 0 24px;
  text-align: center;
  color: #FFFFFF;
}
.promo__title {
  font-size: 60px;
}
.promo__text {
  margin-top: 16px;
  font-size: 28px;
}
.btns {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.btn {
  width: 200px;
  height: 50px;
  padding: 12px 24px;
  color: #FFFFFF;
  background-color: #44F674;
  border: none;
  outline: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.games {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.games__wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}
.games__title {
  color: #FFFFFF;
  font-size: 48px;
  margin: 48px 0 32px;
}
.games__game {
  border: 4px solid #C435BA;
  border-radius: 8px;
  width: 140px;
  height: 190px;
  cursor: pointer;
}
.games__game img{
  border-radius: 4px;
  width: 100%;
  height: 100%;
}
.more {
  color: #FFFFFF;
  padding: 48px 0 0;
  text-align: center;
}
.more__first {
  font-size: 24px;
}
.more__second {
  margin-top: 16px;
}
.more__third {
  margin-top: 24px;
}
footer {
  padding: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  text-align: center;
}
.links{
  display: flex;
  gap: 24px;
}
a {
  color: #FFFFFF;
  text-decoration: none;
}

.register, .login, .video{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: none;
  background: rgba(0, 0, 0, 0.4);
}

form {
  background: #0C618D;
  border: 4px solid #C435BA;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #FFFFFF;
  padding: 40px;
  width: 500px;
  position: relative;
}
.form__title {
  font-size: 32px;
}
form div {
  display: flex;
  gap: 16px;
  width: 100%;
}
form .btn {
  width: 100%;
}
form input, form select{
  border: none;
  outline: none;
  border-radius: 8px;
  height: 50px;
  width: 100%;
  padding: 12px 20px;
  font-size: 18px;
}
iframe {
  background: #FFFFFF;
  max-width: 1000px;
  max-height: 600px;
  width: 100%;
  height: 100%;
  border: 4px solid #C435BA;
  border-radius: 8px;
}

.active {
  display: flex;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0 40px;
  }
  .promo__title {
    font-size: 32px;
  }
  .promo__text {
    margin-top: 16px;
    font-size: 18px;
  }
  .games__title {
    font-size: 24px;
    margin: 24px 0 32px;
  }
  .btns {
    margin-top: 32px;
    flex-direction: column;
    width: 100%;
  }
  .btn{
    width: 100%;
  }
  footer {
    flex-direction: column;
    gap: 16px;
  }
  form div {
    flex-direction: column;
  }
}